import Skeleton from "@mui/material/Skeleton";
import '../styles/Hotel.css';
import Gallery from './GalleryLoader';

const accordionHeader = {
    minHeight: '48px',
    borderRadius: '5px'
}

const Loader = () => {
    return (
        <div className="hotel-page">
            <Skeleton
                animation="wave"
                sx={{
                    position: 'absolute',
                    top: '2rem',
                    left: '2rem',
                    height: '5.5vh',
                    borderRadius: '5px',
                    width: '30vw'
                }} variant="rectangular"></Skeleton>
            <Gallery />
            <div className="side-skeleton">
                <Skeleton animation="wave" variant="rectangular" sx={accordionHeader}></Skeleton>
                <Skeleton animation="wave" variant="rectangular" sx={accordionHeader}></Skeleton>
                <Skeleton animation="wave" variant="rectangular" sx={accordionHeader}></Skeleton>
                <Skeleton animation="wave" variant="rectangular" sx={accordionHeader}></Skeleton>
                <Skeleton animation="wave" variant="rectangular" sx={accordionHeader}></Skeleton>
            </div>
        </div>
    )
}

export default Loader;