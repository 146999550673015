import { create } from "zustand";
import ISelection from "../shared/interfaces/ISelection";

enum DefaultSelection {
  QUEEN_BED = 0,
  BED = 0,
  COT = 0,
  ADULT = 1,
  CHILDREN = 0,
  BABY = 0,
  PET = 0,
}

interface ISearchStore {
  selection: ISelection;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateSelection: (selection: ISelection) => void;
}

const currentDate = new Date();
const tomorrowDate = new Date();
tomorrowDate.setDate(currentDate.getDate() + 1);

const useSearchStore = create<ISearchStore>((set) => ({
  selection: {
    startDate: currentDate,
    endDate: tomorrowDate,
    key: "selection",
    queen_bed: DefaultSelection.QUEEN_BED,
    twin_bed: DefaultSelection.BED,
    cot: DefaultSelection.COT,
    adult: DefaultSelection.ADULT,
    child: DefaultSelection.CHILDREN,
    toddler: DefaultSelection.BABY,
    pet: DefaultSelection.PET,
  },
  open: false,
  setOpen: (open) => set(() => ({ open })), // Setter function to update the 'open' state
  updateSelection: (newSelection: ISelection) => {
    // Setter function to update the 'selection' state
    const updatedSelection: ISelection = {
      ...newSelection,
      startDate:
        typeof newSelection.startDate === "string"
          ? new Date(newSelection.startDate)
          : newSelection.startDate,
      endDate:
        typeof newSelection.endDate === "string"
          ? new Date(newSelection.endDate)
          : newSelection.endDate,
    };

    set(() => ({ selection: updatedSelection }));
  },
}));

export default useSearchStore;
