import ISelection from "@/shared/interfaces/ISelection";
import $host from "@/utils/config/axiosConfig";
import {
  HOTEL_ROUTE,
  ATTRACTIONS_ROUTE,
  ROOM_ROUTE,
  AMENITY_ROUTE,
} from "@/utils/constants/api.routes";
import { AxiosRequestConfig } from "axios";

/* This is a TypeScript module that exports an object called `HotelService`. This object contains three
asynchronous functions: `fetchHotel`, `fetchAttraction`, and `fetchRooms`. */
const HotelService = {
  /* This is an asynchronous function called `fetchHotel` that uses the `await` keyword to wait for the
`.get` method to return a response from the `HOTEL_ROUTE` endpoint. Once the response is
received, it extracts the `data` property from the response object and returns it. */
  async fetchHotel() {
    const { data } = await $host.get(HOTEL_ROUTE);
    return data;
  },

  /* This is an asynchronous function called `fetchAttraction` that uses the `await` keyword to wait for
the `.get` method to return a response from the `ATTRACTIONS_ROUTE` endpoint. Once the response is
received, it extracts the `data` property from the response object and returns it. This function is
likely used to fetch data related to attractions near the hotel. */
  async fetchAttraction() {
    const { data } = await $host.get(ATTRACTIONS_ROUTE);
    return data;
  },

  /* This is an asynchronous function called `fetchRooms` that takes in an argument `selection` of type
`ISelection`. It creates an object `config` of type `AxiosRequestConfig` with a `params` property
set to the `selection` argument. It then uses the `await` keyword to wait for the `.get` method to
return a response from the `ROOM_ROUTE` endpoint with the `config` object as a parameter. Once the
response is received, it extracts the `data` property from the response object and returns it. This
function is likely used to fetch data related to available rooms in the hotel based on a user's
selection criteria. */
  /* This is an asynchronous function called `fetchRooms` that takes in an argument `selection` of type
`ISelection`. It creates an object `config` of type `AxiosRequestConfig` with a `params` property
set to the `selection` argument. It then uses the `await` keyword to wait for the `.get` method to
return a response from the `ROOM_ROUTE` endpoint with the `config` object as a parameter. Once the
response is received, it extracts the `data` property from the response object and returns it. This
function is likely used to fetch data related to available rooms in the hotel based on a user's
selection criteria. */
  async fetchRooms(selection: ISelection) {
    const config: AxiosRequestConfig = {
      params: selection,
    };

    const { data } = await $host.get(ROOM_ROUTE, config);
    return data;
  },

/* This is an asynchronous function called `fetchAmenities` that uses the `await` keyword to wait for
the `.get` method to return a response from the `AMENITY_ROUTE` endpoint. Once the response is
received, it extracts the `data` property from the response object and returns it. This function is
likely used to fetch data related to amenities available in the hotel. */
  async fetchAmenities() {
    const { data } = await $host.get(AMENITY_ROUTE);
    return data;
  },
};

export default HotelService;
