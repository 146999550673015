import { create } from "zustand";
import { IPlace } from "../shared/interfaces/IPlace";
import { IFullRoom } from "../shared/interfaces/IFullRoom";
import IHotel from "../shared/interfaces/IHotel";
import CurrencyType from "@/shared/interfaces/ICurrency";

interface HotelInterface {
  settingOpen: boolean;
  settingSetOpen: (settingOpen: boolean) => void;
  attractions: IPlace[];
  setAttractions: (attractions: IPlace[]) => void;
  hotels: IHotel[];
  room: IFullRoom[];
  setRoom: (room: IFullRoom[]) => void;
  setHotels: (hotels: IHotel[]) => void;
  filterRoom: (
    name?: string,
    min?: string | string[],
    max?: string | string[],
    roomType?: string[] | string,
    amenities?: string[] | string,
    currency?: CurrencyType
  ) => IFullRoom[] | undefined;
  getMaxPrice: (currency: CurrencyType) => number;
  getMinPrice: (currency: CurrencyType) => number;
  getProduct: (hotel: string, room?: string) => IFullRoom | IHotel;
}

const HotelStore = create<HotelInterface>((set, get) => ({
  settingOpen: false,
  hotels: [],
  attractions: [],
  room: [],
  //Filter all rooms by hotel name, min-max price, existing amenities
  filterRoom: (name, min, max, type, amenities, currency = "KZT") => {
    const { room } = get();
    if (room.length === 0) {
      return undefined;
    }

    const maxPrice = Number(Array.isArray(max) ? max[0] : max);
    const minPrice = Number(Array.isArray(min) ? min[0] : min);

    let filteredRooms = room.filter((room) => (!name || room.hotel.en === name) && room.status ==="free");

    if (!isNaN(minPrice)) {
      filteredRooms = filteredRooms.filter((room) =>
        !isNaN(maxPrice)
          ? room.price[currency] >= minPrice && room.price[currency] <= maxPrice
          : room.price[currency] >= minPrice
      );
    } else if (!isNaN(maxPrice)) {
      filteredRooms = filteredRooms.filter(
        (room) => room.price[currency] <= maxPrice
      );
    }
    //adaptive filter for string and string[]
    if (type && (Array.isArray(type) ? type.length > 0 : type)) {
      filteredRooms = filteredRooms.filter((room) =>
        Array.isArray(type) ? type.includes(room.type) : type === room.type
      );
    }
    //adaptive filter for string and string[]
    if (
      amenities &&
      (Array.isArray(amenities) ? amenities.length > 0 : amenities)
    ) {
      filteredRooms = filteredRooms.filter((room) =>
        Array.isArray(amenities)
          ? amenities.every((amenity) =>
              room.amenities.some((item) => item.name.en === amenity)
            )
          : room.amenities.some((item) => item.name.en === amenities)
      );
    }
    return filteredRooms;
  },

  getMaxPrice: (currency = "KZT") => {
    const { room } = get();
    let max = room[0];

    for (let i = 0; i < room.length; i++) {
      if (room[i].price[currency] > max.price[currency]) {
        max = room[i];
      }
    }

    return max?.price[currency] || 0;
  },

  getMinPrice: (currency = "KZT") => {
    const { room } = get();
    let min = room[0];

    for (let i = 0; i < room.length; i++) {
      if (room[i].price[currency] < min.price[currency]) {
        min = room[i];
      }
    }

    return min?.price[currency] || 0;
  },

  getProduct: (hotel, roomName) => {
    const { room: roomInfo, hotels: hotelInfo } = get();
    if (!roomName) {
      return hotelInfo.find((option) => option?.name?.en === hotel) as IHotel;
    }
    return roomInfo.find(
      (room) => room?.hotel?.en === hotel && room?.name?.en === roomName
    ) as IFullRoom;
  },

  //setter for rooms array
  setRoom: (newRooms) => set({ room: newRooms }),
  //setter for attractions
  setAttractions: (attractions) => set({ attractions }),
  //setter for hotels array
  setHotels: (hotels) => set({ hotels }),
  settingSetOpen: (settingOpen) => set({ settingOpen }),
}));

export default HotelStore;
