export const LOGIN_ROUTE = "auth/login";
export const SEND_MAIL = "auth/sendMail";
export const SEND_SMS = "auth/sendSms";
export const REGISTER_ROUTE = "auth/registration";
export const CHECK_EXIST = "auth/userExist";
export const ADDINFO_ROUTE = "user/addinfo";
export const PHONES_ROUTE = "user/phones";
export const SEND_ANCETA = "user/anceta";
export const AUTH_ROUTE = "auth/check";
export const AUTH_APP = "auth/app";
export const HOTEL_ROUTE = "hotel";
export const ROOM_ROUTE = "hotel/room";
export const ATTRACTIONS_ROUTE = "hotel/attraction";
export const REVIEW_ROUTE = "review";
export const BOOKING_VALIDATE_ROUTE = "booking/validate";
export const BOOKING_ROUTE = "booking";
export const AMENITY_ROUTE = "hotel/amenity";
