import { lazy } from "react";
import {
  MAIN_ROUTE,
  HOTEL_ROUTE,
  NOTFOUND_ROUTE,
  // PROFILE_ROUTE,
  CATALOG_ROUTE,
  CHECKOUT_ROUTE,
  AUTH_FORM_ROUTE,
  CONFIRM_ROUTE,
} from "./consts";
import HotelLoader from "@/app/loaders/HotelLoader";
import StaticLoader from "@/app/loaders/Loader";
import MainLoader from "@/app/loaders/MainLoader";

const Main = lazy(() => import("@/page/main"));
const Hotel = lazy(() => import("@/page/Hotel"));
const NotFound = lazy(() => import("@/page/NotFound"));
const Catalog = lazy(() => import("@/page/Catalog"));
const CheckOut = lazy(() => import("@/page/Book"));
const AuthPage = lazy(() => import("@/page/Auth"));
// const ProfilePage = lazy(() => import("@/page/Profile"));
const Confirm = lazy(() => import("@/page/Confirm"));

// export const authRoutes = [
//   {
//     path: PROFILE_ROUTE,
//     Component: ProfilePage,
//     Loader: StaticLoader,
//   },
// ];

export const publicRoutes = [
  {
    path: CONFIRM_ROUTE,
    Component: Confirm,
    Loader: MainLoader,
  },
  {
    path: MAIN_ROUTE,
    Component: Main,
    Loader: MainLoader,
  },
  {
    path: HOTEL_ROUTE,
    Component: Hotel,
    Loader: HotelLoader,
  },
  {
    path: NOTFOUND_ROUTE,
    Component: NotFound,
    Loader: StaticLoader,
  },
  {
    path: CATALOG_ROUTE,
    Component: Catalog,
    Loader: StaticLoader,
  },
  {
    path: CHECKOUT_ROUTE,
    Component: CheckOut,
    Loader: StaticLoader,
  },
  {
    path: AUTH_FORM_ROUTE,
    Component: AuthPage,
    Loader: StaticLoader,
  },
];
