import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/styles/index.css';
import App from './App';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./app/langs/en.json";
import ruTranslation from "./app/langs/ru.json";
import kzTranslation from "./app/langs/kz.json";
import { I18nextProvider } from "react-i18next";
import AxiosProvider from './providers/UserProvider';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ru: { translation: ruTranslation },
    kz: { translation: kzTranslation },
  },
  lng: "en",
  fallbackLng: "en",
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
    {/* <React.StrictMode> */}
    <AxiosProvider>
      <App />
    </AxiosProvider>
    {/* </React.StrictMode> */}
  </I18nextProvider>
);