import axios from "axios";
import useAuthStore from "../../store/user.store";

/* This code is creating an instance of the Axios library with a base URL, headers, and timeout. It
then adds two request interceptors to modify the headers of outgoing requests. The first interceptor
adds an "Accept-Language" header based on the language stored in the user's client information. The
second interceptor adds an "Authorization" header with a bearer token retrieved from local storage.
Finally, the instance is exported for use in making HTTP requests to the API. */
const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
  timeout: 5000,
});

$host.interceptors.request.use(
  (config) => {
    const language = useAuthStore.getState().clientInfo.lang;
    config.headers["Accept-Language"] = language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const authInterceptor = (config: any) => {
  config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
};

$host.interceptors.request.use(authInterceptor);

export default $host;
