import { Suspense, memo, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { publicRoutes } from './constants/routes';
import { NOTFOUND_ROUTE } from './constants/consts';
import useSearchStore from '../store/search.store';
import CartStore from '../store/cart.store';

const AppRouter = memo(() => {
  const { cart, setCart } = CartStore();
  const navigate = useNavigate();
  const { selection, updateSelection } = useSearchStore();
  const location = useLocation();

  // if (!auth) {
  //   for (const { path } of authRoutes) {
  //     if (window.location.pathname === path) {
  //       navigate(LOGIN_ROUTE, { replace: true });
  //       break;
  //     }
  //   }
  // }

  useEffect(() => {
    const locSearch = new URLSearchParams(location.search);
    const rooms = locSearch.get('rooms');
    const cart = locSearch.get('cart');
    rooms && updateSelection(JSON.parse(decodeURIComponent(rooms)))
    cart && setCart(JSON.parse(decodeURIComponent(cart)));
  }, []);

  useEffect(() => {
    const locSearch = new URLSearchParams(location.search);
    locSearch.set('cart', encodeURIComponent(JSON.stringify(cart)));
    window.history.replaceState({}, '', `${location.pathname}?${locSearch.toString()}`);
  }, [cart, location.pathname, location.search, navigate]);

  useEffect(() => {
    const locSearch = new URLSearchParams(location.search);
    locSearch.set('rooms', encodeURIComponent(JSON.stringify(selection)));
    window.history.replaceState({}, '', `${location.pathname}?${locSearch.toString()}`);
  }, [selection, location.pathname, location.search, navigate]);


  return (
    <Routes>
      {publicRoutes.map(({ path, Component, Loader }) => (
        <Route key={path} path={path} element={<Suspense fallback={<Loader />}><Component /></Suspense>} />
      ))}
      {/* {authRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))} */}
      <Route path="*" element={<Navigate to={NOTFOUND_ROUTE} />} />
    </Routes>
  );
});

export default AppRouter;
