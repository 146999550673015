export const MAIN_ROUTE = "/";
export const HOTEL_ROUTE = "/hotel/:name/:room?";
export const NOTFOUND_ROUTE = "/errorpage";
export const CATALOG_ROUTE = "/catalog";
export const CHECKOUT_ROUTE = "/cart";
export const CONFIRM_ROUTE = "/confirm";

export const AUTH_FORM_ROUTE = "/auth/:form";
export const LOGIN_ROUTE = "/auth/login";
export const SIGNIN_ROUTE = "/auth/signin";
export const SIGNINPHONE_ROUTE = "/auth/phone";

export const PROFILE_ROUTE = "/profile/:page";
export const HISTORY_ROUTE = "/profile/history";
export const SAVED_ROUTE = "/profile/saved";
export const SETTINGS_ROUTE = "/profile/settings";
export const SETTINGS_PERSONAL = "/profile/settings?form=personal";
export const SETTINGS_PREFERENCE = "/profile/settings?form=preference";
export const SETTINGS_SECURITY = "/profile/settings?form=security";
export const SETTINGS_PAYMENT = "/profile/settings?form=payment";
export const SETTINGS_PRIVACY = "/profile/settings?form=privacy";
export const SETTINGS_EMAIL = "/profile/settings?form=email";
