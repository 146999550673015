import logo from '../../assets/images/testlogotest.webp';

const Loader = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'relative',
        }}>
            <img
                width={'30%'}
                src={logo}
                alt="logo"
            />
            <div style={{
                position: 'absolute',
                bottom: 15,
                textAlign: 'center',
                width: '100%'
            }}>
                FROM
                < br />
                MAXINUM CONSULTING
            </div>
        </div>
    )
}

export default Loader;
