import detect from "@/utils/helpers/checkPhone";
import Skeleton from "@mui/material/Skeleton";

const Loader = () => {

    const thumbnailStyle = {
        width: '60px',
        height: "60px",
        borderRadius: '5px'
    }

    const isMobile = detect();
    return (
        <div style={{
            height: isMobile ? '35vh' : '100vh',
            width: '100%',
            position: 'relative',
            borderBottom: '1px solid #e6e6e6'
        }} className="image-gallery image-gallery--over-sliding image-gallery-using-mouse">
            <div style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                minWidth: "250px",
                width: "40%",
                margin: "0 auto",
            }}>
                <div className="image-gallery-thumbnails" style={{
                    justifyContent: 'space-between',
                    height: '80px',
                    display: isMobile ? 'none' : 'flex',
                    alignItems: 'center',
                }}>
                    <Skeleton animation="wave" variant="rectangular" sx={thumbnailStyle} />
                    <Skeleton animation="wave" variant="rectangular" sx={thumbnailStyle} />
                    <Skeleton animation="wave" variant="rectangular" sx={thumbnailStyle} />
                    <Skeleton animation="wave" variant="rectangular" sx={thumbnailStyle} />
                    <Skeleton animation="wave" variant="rectangular" sx={thumbnailStyle} />
                </div>
            </div>
        </div >
    )
}

export default Loader;
