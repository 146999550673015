import { useEffect } from "react";
import axios from "../utils/config/axiosConfig";
import { AxiosRequestConfig, AxiosError, InternalAxiosRequestConfig } from 'axios'
import useAuthStore from "../store/user.store";

interface UserProviderProps {
    children: React.ReactNode;
}

/**
 * The AxiosProvider component sets up interceptors for Axios requests and responses, adding language
 * and currency headers based on user authentication information.
 * @param  - The `AxiosProvider` component is a React functional component that takes in a single prop
 * called `children`, which represents the child components that will be rendered within the provider.
 * @returns The `AxiosProvider` component is being returned, which wraps its children with an Axios
 * interceptor that adds the `lang` and `currency` headers to each request made with Axios.
 */
const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const { clientInfo, currency } = useAuthStore()
    useEffect(() => {
        const errorHandler = (error: AxiosError) => {
            return Promise.reject(error);
        };

        const onRequest = (req: AxiosRequestConfig) => {

            if (req.headers) {
                req.headers.lang = clientInfo.lang;
                req.headers.currency = currency;
            }

            return req;
        };
        const reqInterceptor = axios.interceptors.request.use(
            onRequest as (value: InternalAxiosRequestConfig<any>) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>,
            errorHandler
        );
        const resInterceptor = axios.interceptors.response.use(
            undefined,
            errorHandler
        );
        return () => {
            axios.interceptors.request.eject(reqInterceptor);
            axios.interceptors.response.eject(resInterceptor);
        };
    }, [currency, clientInfo.lang]);

    return <>{children}</>;
};

export default UserProvider;
