import { create } from "zustand";
import ICart from "../shared/interfaces/ICart";
interface ICartStore {
  cart: ICart[];
  setCart: (newCartItem: ICart | ICart[]) => void;
  deleteFromCart: (newCartItem: ICart) => void;
  clearCart: () => void;
  checkExistCart: (newCartItem: ICart) => boolean;
  updateCartItem: (newCartItem: ICart, quantity?: number) => void;
  caclTotal: (sale?: number) => { [key: string]: number };
}

const useCartStore = create<ICartStore>((set, get) => ({
  cart: [],
  setCart: (newCartItem) => {
    // Check if the newCartItem already exists in the cart
    const cartItemExists =
      !Array.isArray(newCartItem) && get().checkExistCart(newCartItem);
    const data =
      Array.isArray(newCartItem) &&
      newCartItem.map((item) => ({
        ...item,
        startDate: new Date(item.check_in),
        endDate: new Date(item.check_out),
        quantity: item.quantity,
      }));
    console.log(data);
    set((state) => ({
      cart: Array.isArray(newCartItem)
        ? newCartItem.map((item) => ({
            ...item,
            startDate: new Date(item.check_in),
            endDate: new Date(item.check_out),
            quantity: item.quantity,
          }))
        : [
            ...state.cart,
            {
              ...newCartItem,
              startDate: new Date(newCartItem.check_in),
              endDate: new Date(newCartItem.check_out),
              quantity: cartItemExists
                ? newCartItem.quantity + 1
                : newCartItem.quantity || 1,
            },
          ],
    }));
  },
  updateCartItem: (cartItem, quantity) => {
    set((state) => ({
      cart: state.cart.map((item) =>
        // Check if the cartItem matches the provided cartItem
        cartItem.name.en === item.name.en &&
        cartItem.hotel.en === item.hotel.en &&
        cartItem.check_out.toISOString() === item.check_out.toISOString() &&
        cartItem.check_in.toISOString() === item.check_in.toISOString()
          ? {
              ...item,
              quantity: quantity !== undefined ? quantity : item.quantity,
            }
          : item
      ),
    }));
  },
  checkExistCart: (newCartItem) => {
    // Check if the newCartItem already exists in the cart
    return get().cart.some(
      (cartItem) =>
        cartItem.name.en === newCartItem.name.en &&
        cartItem.hotel.en === newCartItem.hotel.en &&
        new Date(cartItem.check_out).toISOString() ===
          new Date(newCartItem.check_out).toISOString() &&
        new Date(cartItem.check_in).toISOString() ===
          new Date(newCartItem.check_in).toISOString()
    );
  },
  clearCart: () => {
    // Clear the cart
    set(() => ({ cart: [] }));
  },
  deleteFromCart: (newCartItem) => {
    set((state) => {
      const index = state.cart.findIndex(
        (item) => item.name === newCartItem.name
      );
      if (index !== -1) {
        // Remove the item from the cart
        const updatedCart = [...state.cart];
        updatedCart.splice(index, 1);
        return {
          cart: updatedCart,
        };
      } else {
        return state;
      }
    });
  },
  caclTotal: (sale = 0) => {
    const { cart } = get();
    const totals: { [key: string]: number } = {};

    cart.forEach((item) => {
      const { check_out, check_in, price, quantity } = item;

      if (check_out instanceof Date && check_in instanceof Date) {
        const duration = Math.ceil(
          (check_out.getTime() - check_in.getTime()) / (1000 * 60 * 60 * 24)
        );

        Object.entries(price).forEach(([currency, itemPrice]) => {
          const totalPrice = itemPrice * duration * quantity * (1 - sale / 100);

          if (totals[currency]) {
            totals[currency] += totalPrice;
          } else {
            totals[currency] = totalPrice;
          }
        });
      }
    });

    return totals;
  },
}));

export default useCartStore;
