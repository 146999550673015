import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { BrowserRouter } from 'react-router-dom';
import useAuthStore from './store/user.store';
import AppRouter from './utils/AppRouter';
import HotelStore from './store/hotel.store';
import HotelService from './services/hotel.service';

const App = () => {
  const { fetchHotel } = HotelService;
  const { i18n } = useTranslation();
  const { setClientInfo } = useAuthStore();
  const { setHotels } = HotelStore();

  useEffect(() => {
    const userLanguage = navigator.language.substring(0, 2);
    const lang = userLanguage !== 'ru' && userLanguage !== 'kz' ? 'en' : userLanguage;
    fetchHotel().then(data => setHotels(data));
    i18n.changeLanguage(lang);
    setClientInfo({ lang });
  }, []);

  
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
