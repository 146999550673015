/**
 * The function detects if the user agent is a mobile device or Android.
 * @returns The function `detect` is returning a boolean value indicating whether the user agent string
 * contains the substring "Mobi" or "Android". This is used to detect if the user is accessing the
 * website from a mobile device or an Android device.
 */
const detect = () => {
    const userAgent = window.navigator.userAgent;
    return /Mobi|Android/i.test(userAgent);
}

export default detect;