import { create } from "zustand";
import IClientInfo from "../shared/interfaces/IClientInfo";
import CurrencyType from "@/shared/interfaces/ICurrency";

interface Phone {
  code: string;
  country: string;
  img: string;
}

interface IAuthStore {
  auth: boolean;
  registerNow: boolean;
  clientInfo: IClientInfo;
  setClientInfo: (newClientInfo: Partial<IClientInfo>) => void;
  currency: CurrencyType;
  phones: Phone[];
  setPhones: (phones: Phone[]) => void;
  setCurrency: (currency: CurrencyType) => void;
  setAuth: (auth: boolean) => void;
  setRegister: (registerNow: boolean) => void;
}

const useUserStore = create<IAuthStore>((set, get) => ({
  auth: false,
  registerNow: false,
  phones: [],
  clientInfo: {
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    last_name: "",
    phone: "",
    lang: "en",
    country: "",
    goverment_id: "",
    comment: "",
  },
  currency: "KZT",
  setPhones: (phones) => set({ phones }),
  setCurrency: (currency) => set(() => ({ currency })),
  setAuth: (auth) => set(() => ({ auth })),
  setRegister: (registerNow) => set(() => ({ registerNow })),
  setClientInfo: (newClientInfo) =>
    set((state) => ({ clientInfo: { ...state.clientInfo, ...newClientInfo } })),
}));

export default useUserStore;
